import { Chip, MenuItem, Select, Typography } from "@mui/material";

export function PaymentStatusLabel({ status }) {
  if (status === "pending") {
    return <Chip label="Pending" variant="outlined" sx={{ borderColor: "#f44336", color: "#f44336" }} />;
  }
  if (status === "authorized") {
    return <Chip label="Authorized" variant="outlined" sx={{ borderColor: "#0288d1", color: "#0288d1" }} />;
  }
  if (status === "completed") {
    return <Chip label="Completed" variant="outlined" sx={{ borderColor: "#388e3c", color: "#388e3c" }} />;
  }
}

export function StatusLabel({ value, onChange, disabled }) {
  return (
    <>
      {value === "delivered" || value === "cancelled" ? (
        <Typography className="text-capitalize">{value}</Typography>
      ) : (
        <Select
          value={value !== null && value}
          defaultValue={value !== null && value}
          onChange={onChange}
          disabled={disabled}
          className="orderTableSelect"
        >
          <MenuItem value={"pending"} disabled={true}>
            Pending
          </MenuItem>
          <MenuItem
            value={"confirmed"}
            disabled={value === "confirmed" || value === "shipped" || value === "delivered" || value === "cancelled"}
          >
            Confirmed
          </MenuItem>
          <MenuItem value={"shipped"} disabled={value === "shipped" || value === "delivered" || value === "cancelled"}>
            Shipped
          </MenuItem>
          <MenuItem value={"delivered"} disabled={value === "delivered" || value === "cancelled"}>
            Delivered
          </MenuItem>
          <MenuItem value={"cancelled"} disabled={value === "cancelled"}>
            Cancelled
          </MenuItem>
        </Select>
      )}
    </>
  );
}
