// login module types
export const LOG_OUT = "LOG_OUT";
export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const AUTH_CHEK_REQUEST = "AUTH_CHEK_REQUEST";
export const AUTH_CHEK_SUCCESS = "AUTH_CHEK_SUCCESS";
export const AUTH_CHEK_FAIL = "AUTH_CHEK_FAIL";
export const REFRESH_CAPTCHA_REQUEST = "REFRESH_CAPTCHA_REQUEST";
export const REFRESH_CAPTCHA_SUCCESS = "REFRESH_CAPTCHA_SUCCESS";
export const REFRESH_CAPTCHA_FAIL = "REFRESH_CAPTCHA_FAIL";
export const CHEK_CAPTCHA_REQUEST = "CHEK_CAPTCHA_REQUEST";
export const CHEK_CAPTCHA_SUCCESS = "CHEK_CAPTCHA_SUCCESS";
export const CHEK_CAPTCHA_FAIL = "CHEK_CAPTCHA_FAIL";
export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";
