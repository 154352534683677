// import CryptoAES from "crypto-js/aes";
// import CryptoENC from "crypto-js/enc-utf8";

import { GridPrintExportMenuItem, GridToolbarExportContainer } from "@mui/x-data-grid";

// const secretKey = "sOV987RaviNWjR0AadD4rdxs01lwH365";

// export const tz = (() => {
//   if (Intl && Intl.DateTimeFormat()) return Intl.DateTimeFormat().resolvedOptions().timeZone;
//   else return "America/New_York";
// })();

// export const currencyFormat = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   minimumFractionDigits: 2
// }).format;

// export const formatPhoneNumber = (phoneNumberString) => {
//   const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
//   const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     const intlCode = match[1] ? "+1 " : "";
//     const formattedNumber = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
//     if (!formattedNumber) return phoneNumberString;
//     return formattedNumber;
//   }else{
//   return phoneNumberString;
//   }
// };

export const styles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    textTransform: "capitalize",
    backgroundColor: isDisabled ? undefined : isSelected ? "#3d426f" : isFocused ? "#e5e4e4" : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : isFocused ? "#000" : "#000",
    cursor: isDisabled ? "not-allowed" : "pointer"
  }),
  control: (styles) => ({
    ...styles,
    fontWeight: 400,
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87) !important",
    padding: "4px 5px",
    cursor: "pointer",
    backgroundColor: "transparent"

    // borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    // ":active": { boxShadow: "none", border: "none" }
  })
};

export const dependentRoles = {
  category_add: "category_list",
  category_view: "category_list",
  category_edit: "category_list",
  category_delete: "category_list",
  user_add: "user_list",
  user_view: "user_list",
  user_edit: "user_list",
  user_delete: "user_list",
  tax_add: "tax_list",
  tax_view: "tax_list",
  tax_edit: "tax_list",
  tax_delete: "tax_list",
  vendor_add: "vendor_list",
  vendor_view: "vendor_list",
  vendor_edit: "vendor_list",
  vendor_delete: "vendor_list",
  customer_add: "customer_list",
  customer_view: "customer_list",
  customer_edit: "customer_list",
  customer_delete: "customer_list",
  inventory_add: "inventory_list",
  inventory_view: "inventory_list",
  inventory_edit: "inventory_list",
  inventory_delete: "inventory_list",
  order_view: "order_list",
  order_add: "order_list",
  order_edit: "order_list",
  order_delete: "order_list",
  payment_add: "payment_view",
  payment_edit: "payment_view",
  payment_view: "order_view",
  product_add: "product_list",
  product_view: "product_list",
  product_edit: "product_list",
  product_delete: "product_list",
  sub_product_list: "product_list",
  sub_product_edit: "sub_product_list",
  sub_product_add: "sub_product_list",
  sub_product_delete: "sub_product_list"
};

export const GridToolbarExport = ({ csvOptions, printOptions, ...other }) => (
  <GridToolbarExportContainer {...other}>
    <GridPrintExportMenuItem options={printOptions} />
  </GridToolbarExportContainer>
);

// export const searchFilterRestricted = (e) => {
//   let flag = e.key.search(/^[a-zA-Z0-9]+$/) === -1 ? false : true;
//   if (!flag && ![".", "@", " "].includes(e.key)) e.preventDefault();
// };

// export const onlyNumbers = (string) => {
//   if (string !== "") {
//     var letters = /[0-9]/g;
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const onlyAlpha = (string) => {
//   if (string !== "") {
//     var letters = /[a-z]/gi;
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const numberWithSpecialChar = (string, char) => {
//   if (string !== "") {
//     var letters = new RegExp("[0-9" + char + "]", "g");
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const alphaWithSpecialChar = (string, char) => {
//   if (string !== "") {
//     var letters = new RegExp("[a-z" + char + "]", "gi");
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const capitalizeEachWord = (string) => {

//   let arr = string.split(" ");

//   for (var i = 0; i < arr.length; i++) {
//       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

//   }

//   let str2 = arr.join(" ");
//   return str2

// }

// export const checkCapLock = () => {

//   window.addEventListener('keydown', detectCapsLock)
//   window.addEventListener('keyup', detectCapsLock)

//   function detectCapsLock(e) {
//     if (typeof e.getModifierState == "function" && e.getModifierState('CapsLock')) {
//       return true
//     } else {
//       return false
//     }
//   }

// }

// export const decryptResponse = (data) => {
//   try {
//     if (process.env.REACT_APP_ENV === "development") {
//       return data;
//     } else {
//       var bytes = CryptoAES.decrypt(data, secretKey);
//       return JSON.parse(bytes.toString(CryptoENC));
//     }
//   } catch (error) {
//     throw error;
//   }
//   // if (process.env.REACT_APP_ENV === "development") {
//   //   return data;
//   // } else {
//   //   var bytes = CryptoAES.decrypt(data, secretKey);
//   //   return JSON.parse(bytes.toString(CryptoENC));
//   // }
// };

// export const clearLocalStorage = (arrList) => {
//   if(arrList == null){
//     localStorage.clear()
//   }
//   else{
//     if(arrList.length > 0){
//       for(let x of arrList){
//         localStorage.removeItem(x)
//       }
//     }
//   }
// }

// export const getLogin = () => {
//   const companyName = localStorage.getItem("companyName")
//   if(companyName != null){
//     return true
//   }
//   else{
//     return false
//   }
// }

// export const GETCITYSTATEAPI = "https://www.prominentgames.com/us_zip_city/get.php";
