// Order module types
export const OrderMap = {
  ORDER_REQUEST: "ORDER_REQUEST",
  ORDER_SUCCESS: "ORDER_SUCCESS",
  ORDER_FAIL: "ORDER_FAIL",

  ORDER_ADD_REQUEST: "ORDER_ADD_REQUEST",
  ORDER_ADD_SUCCESS: "ORDER_ADD_SUCCESS",
  ORDER_ADD_FAIL: "ORDER_ADD_FAIL",

  ORDER_UPDATE_REQUEST: "ORDER_UPDATE_REQUEST",
  ORDER_UPDATE_SUCCESS: "ORDER_UPDATE_SUCCESS",
  ORDER_UPDATE_FAIL: "ORDER_UPDATE_FAIL",

  SINGLE_ORDER_REQUEST: "SINGLE_ORDER_REQUEST",
  SINGLE_ORDER_SUCCESS: "SINGLE_ORDER_SUCCESS",
  SINGLE_ORDER_FAIL: "SINGLE_ORDER_FAIL",

  ORDER_DELETE_REQUEST: "ORDER_DELETE_REQUEST",
  ORDER_DELETE_SUCCESS: "ORDER_DELETE_SUCCESS",
  ORDER_DELETE_FAIL: "ORDER_DELETE_FAIL",

  CUSTOMER_DROPDOWN_REQUEST: "CUSTOMER_DROPDOWN_REQUEST",
  CUSTOMER_DROPDOWN_SUCCESS: "CUSTOMER_DROPDOWN_SUCCESS",
  CUSTOMER_DROPDOWN_FAIL: "CUSTOMER_DROPDOWN_FAIL",

  FIND_INVENTORY_REQUEST: "FIND_INVENTORY_REQUEST",
  FIND_INVENTORY_SUCCESS: "FIND_INVENTORY_SUCCESS",
  FIND_INVENTORY_FAIL: "FIND_INVENTORY_FAIL",

  SEARCH_INVENTORY_REQUEST: "SEARCH_INVENTORY_REQUEST",
  SEARCH_INVENTORY_SUCCESS: "SEARCH_INVENTORY_SUCCESS",
  SEARCH_INVENTORY_FAIL: "SEARCH_INVENTORY_FAIL",

  PAYMENT_ADD_REQUEST: "PAYMENT_ADD_REQUEST",
  PAYMENT_ADD_SUCCESS: "PAYMENT_ADD_SUCCESS",
  PAYMENT_ADD_FAIL: "PAYMENT_ADD_FAIL",

  PDF_GET_REQUEST: "PDF_GET_REQUEST",
  PDF_GET_SUCCESS: "PDF_GET_SUCCESS",
  PDF_GET_FAIL: "PDF_GET_FAIL",

  PAYMENT_EDIT_REQUEST: "PAYMENT_EDIT_REQUEST",
  PAYMENT_EDIT_SUCCESS: "PAYMENT_EDIT_SUCCESS",
  PAYMENT_EDIT_FAIL: "PAYMENT_EDIT_FAIL",

  PAYMENT_CLEAR_REQUEST: "PAYMENT_CLEAR_REQUEST",
  PAYMENT_CLEAR_SUCCESS: "PAYMENT_CLEAR_SUCCESS",
  PAYMENT_CLEAR_FAIL: "PAYMENT_CLEAR_FAIL"
};
