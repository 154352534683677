// user module types
export const UserMap = {
  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_FAIL: "USERS_FAIL",

  USERS_ADD_REQUEST: "USERS_ADD_REQUEST",
  USERS_ADD_SUCCESS: "USERS_ADD_SUCCESS",
  USERS_ADD_FAIL: "USERS_ADD_FAIL",

  USERS_UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
  USERS_UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
  USERS_UPDATE_FAIL: "USERS_UPDATE_FAIL",

  USERS_DELETE_REQUEST: "USERS_DELETE_REQUEST",
  USERS_DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  USERS_DELETE_FAIL: "USERS_DELETE_FAIL",

  USERS_ROLE_REQUEST: "USERS_ROLE_REQUEST",
  USERS_ROLE_SUCCESS: "USERS_ROLE_SUCCESS",
  USERS_ROLE_FAIL: "USERS_ROLE_FAIL",

  USERS_ROLE_ASSIGN_REQUEST: "USERS_ROLE_ASSIGN_REQUEST",
  USERS_ROLE_ASSIGN_SUCCESS: "USERS_ROLE_ASSIGN_SUCCESS",
  USERS_ROLE_ASSIGN_FAIL: "USERS_ROLE_ASSIGN_FAIL",

  ALL_COMPANIES_REQUEST: "ALL_COMPANIES_REQUEST",
  ALL_COMPANIES_SUCCESS: "ALL_COMPANIES_SUCCESS",
  ALL_COMPANIES_FAIL: "ALL_COMPANIES_FAIL"
};
